
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useOrgStore } from '@/store/orgStore';
import NavBar from '@/components/NavBar.vue';

interface INavBar {
  closeNav(): void;
}

export default defineComponent({
  components: {
    NavBar,
  },
  computed: mapState(useOrgStore, ['currentOrg']),
  methods: {
    closeNav() {
      (this.$refs.dashboardNav as INavBar).closeNav();
    },
  },
  props: {
    showLinkingSite: {
      type: Boolean,
      default: true,
    },
  },
});
