
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useAuthStore } from '@/store/authStore';
import { useOrgStore } from '@/store/orgStore';
import { SubscriptionPlan } from '@/entities/subscriptionPlan';
import Badge from '../Badge.vue';
import ExternalAnchor from '../ExternalAnchor.vue';

export default defineComponent({
  components: {
    Badge,
    ExternalAnchor,
  },
  computed: {
    ...mapState(useAuthStore, ['customerID']),
    ...mapState(useOrgStore, ['currentOrg', 'permissions']),
    isOwner(): boolean {
      return !!this.currentOrg && this.currentOrg.ownerId.toString() === this.customerID;
    },
    isPro(): boolean {
      return !!this.currentOrg && this.currentOrg.subscriptionPlan === SubscriptionPlan.Pro;
    },
  },
});
