
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { getOrgs } from '@/services/steamcord';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import Banner from '@/components/Banner.vue';
import DashboardNavBar from '@/components/dashboard/DashboardNavBar.vue';
import SideNav from '@/components/dashboard/SideNav.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const mainStore = useMainStore();
    mainStore.loading = true;

    const orgs = await getOrgs();
    const orgStore = useOrgStore();
    orgStore.orgs = orgs;

    if (orgs.length === 0 && to.name !== 'Organizations') {
      mainStore.loading = false;
      next({ name: 'New Organization', replace: true });
      return;
    }

    next();
  },
  components: {
    Banner,
    DashboardNavBar,
    SideNav,
  },
  computed: {
    ...mapState(useOrgStore, ['currentOrg']),
    showBanner(): boolean {
      return !!this.$route.params.subdomain && !this.currentOrg?.subscriptionActive
          && this.$route.name !== 'Billing';
    },
    showSideNav(): boolean {
      return !this.$route.meta?.hideSideNav;
    },
    showTitle(): boolean {
      return !this.$route.meta?.hideTitle;
    },
  },
});
